import api from ".";

export const getEarningStateData = (type, name) => {
  return api.get("/charts/get-earning-chart-data", {
    params: {
      type,
      name,
    },
  });
};

export const getGreedyEarningStateData = (type) => {
  return api.get("/charts/get-greedy-earning-chart-data", {
    params: {
      type,
    },
  });
};

export const getTeenPattiEarningStateData = (type) => {
  return api.get("/charts/get-teen-patti-earning-chart-data", {
    params: {
      type,
    },
  });
};

export const getLucky77EarningStateData = (type) => {
  return api.get("/charts/get-lucky-77-earning-chart-data", {
    params: {
      type,
    },
  });
};

export const getHourlyChartData = (name) => {
  return api.get("/charts/get-hourly-expense-and-earnings-chart-data", {
    params: {
      name,
    },
  });
};
