import { getSettings } from "api/dashboard";
import { saveConfiguration } from "api/dashboard";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

const Settings = ({ gameName, configName, title }) => {
  const id1 = `id_${Math.floor(Math.random() * 5000) + 1}_id`;
  const id2 = `id_${Math.floor(Math.random() * 5000) + 1}_id`;
  const id3 = `id_${Math.floor(Math.random() * 5000) + 1}_id`;

  const [status, setStatus] = useState("off");
  const [isLoading, setLoading] = useState(true);

  const onSettingChange = (value) => {
    setStatus(value);

    saveConfiguration(gameName, value)
      .then(() => {
        NotificationManager.success("Configuration Saved.");
      })
      .catch((err) => {
        NotificationManager.error(err?.response?.data?.issue?.message || "Unknown error.");
      });
  };

  const loadSettings = async () => {
    try {
      setLoading(true);

      const { data } = await getSettings();

      setStatus(data[configName]);
      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    loadSettings();
  }, []);

  if (isLoading == false) {
    return (
      <Col xs={12} lg={4} className="mt-2">
        <div className="card">
          <div className="card-header">
            <h4>{title}</h4>
          </div>
          <div className="card-body">
            <div className="mb-2">
              <input type="radio" checked={status == "off"} onChange={(e) => onSettingChange("off")} name={configName} id={id1} /> <label htmlFor={id1}> Off</label>
            </div>
            <div className="mb-2">
              <input type="radio" checked={status == "normal"} onChange={(e) => onSettingChange("normal")} name={configName} id={id2} /> <label htmlFor={id2}> Normal Mode</label>
            </div>
            <div className="mb-2">
              <input type="radio" checked={status == "hard"} onChange={(e) => onSettingChange("hard")} name={configName} id={id3} /> <label htmlFor={id3}> Hard mode</label>
            </div>
          </div>
        </div>
      </Col>
    );
  }

  return <></>;
};

export default Settings;
