import { changeDP } from "api/aladin";
import { getAladinStats } from "api/aladin";
import React, { useEffect, useState } from "react";

import { Button, Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { NotificationManager } from "react-notifications";

function TableList() {
  const [rio, setRio] = useState();

  const handleSave = () => {
    if (!rio) return;

    changeDP(rio)
      .then(() => {
        NotificationManager.success("Configuration Saved.");
      })
      .catch((err) => {
        NotificationManager.error(err?.response?.data?.issue?.message || "Unknown error.");
      });
  };

  const loadSettings = async () => {
    try {
      const { data } = await getAladinStats();

      setRio(data?.atdp);
    } catch (error) {}
  };

  useEffect(() => {
    loadSettings();
  }, []);

  return (
    <>
      <Container fluid>
        <Row className="justify-content-center">
          <Col md="2">
            <div className="mb-2">
              <Form.Label>Return on Invest</Form.Label>
            </div>

            <InputGroup className="mb-3">
              <Form.Control type="number" placeholder="RIO" aria-label="RIO" value={rio} onChange={(e) => setRio(e.target.value)} />
              <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
            </InputGroup>

            <div className="d-grid gap-2">
              <Button onClick={handleSave}>Save</Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TableList;
