import api from ".";

export const getWinRate = () => {
  return api.get("/teen_patti/get_win_rate");
};

export const updateWinRate = (a, b, c) => {
  return api.put("/teen_patti/update_win_rate", {
    a,
    b,
    c,
  });
};

export const getPlayers = (skip = 0, limit = 20, orders = []) => {
  return api.get("/teen_patti/players", {
    params: {
      skip,
      limit,
      orders: JSON.stringify(orders),
    },
  });
};

export const getHistory = () => {
  return api.get(`${process.env.REACT_APP_SOCKET_BASE_URL}/game-history`, {
    params: {
      uid: process.env.REACT_APP_SPECIAL_USER_JWT,
    },
  });
};
