import api from ".";

export const getStats = () => {
  return api.get("/dashboard/get_stats");
};

export const getSettings = () => {
  return api.get(`/settings`);
};

export const saveConfiguration = (name, value) => {
  return api.put(`/settings`, { name, value });
};
