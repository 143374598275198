import { configureStore } from "@reduxjs/toolkit";
import auth from "./slice/auth";
import teenPatti from "./slice/teenPatti";
import lucky77 from "./slice/lucky77";
import dashboard from "./slice/dashboard";

const store = configureStore({
  reducer: {
    auth,
    teenPatti,
    lucky77,
    dashboard,
  },
});

export default store;
