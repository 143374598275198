import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  winRate: {
    a: 0,
    b: 0,
    c: 0,
  },
};

export const teenPatti = createSlice({
  name: "teenPatti",
  initialState,
  reducers: {
    changeWinRate: (state, { payload }) => {
      state.winRate = payload;
    },
  },
});

export const { changeWinRate } = teenPatti.actions;

export default teenPatti.reducer;
