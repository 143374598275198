import { changeDP } from "api/aladin";
import { getAladinStats } from "api/aladin";
import { saveConfiguration } from "api/dashboard";
import { getSettings } from "api/dashboard";
import Percentage from "components/Settings/Percentage";
import React, { useEffect, useState } from "react";

import { Button, Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { FormGroup, Input, Label } from "reactstrap";

function TableList() {
  return <Percentage name="roulette" />;
}

export default TableList;
