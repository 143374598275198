import React from "react";
import { useState } from "react";

// react-bootstrap components
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { updateWinRate } from "api/lucky77";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeWinRate } from "store/slice/lucky77";

function Lucky77Settings({ show, onHide }) {
  const dispatch = useDispatch();
  const winRate = useSelector((state) => state.lucky77.winRate);

  const [isLoading, setLoading] = useState(false);
  const [boardA, setBoardA] = useState(parseInt(winRate.a));
  const [boardB, setBoardB] = useState(parseInt(winRate.b));
  const [boardC, setBoardC] = useState(parseInt(winRate.c));

  const handleSave = async () => {
    try {
      if (boardA + boardB + boardC === 100) {
        setLoading(true);
        await updateWinRate(boardA, boardB, boardC);
        setLoading(false);
        dispatch(changeWinRate({ a: boardA, b: boardB, c: boardC }));

        NotificationManager.success("Change successfully saved.");
        onHide();
      } else {
        NotificationManager.error("Total win rate must be 100%");
      }
    } catch (error) {
      setLoading(false);

      NotificationManager.error(
        error?.response?.data?.error?.message || error.message
      );
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Change Win Rate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form.Label>Pot A</Form.Label>

            {/* <Select options={options} value={options[3]} /> */}

            <Form.Select
              aria-label="Default select example"
              onChange={(e) => {
                setBoardA(parseInt(e.target.value));
              }}
              value={boardA}
            >
              <option value="0">0%</option>
              <option value="10" disabled={boardB + boardC > 90}>
                10%
              </option>
              <option value="20" disabled={boardB + boardC > 80}>
                20%
              </option>
              <option value="30" disabled={boardB + boardC > 70}>
                30%
              </option>
              <option value="40" disabled={boardB + boardC > 60}>
                40%
              </option>
              <option value="50" disabled={boardB + boardC > 50}>
                50%
              </option>
              <option value="60" disabled={boardB + boardC > 40}>
                60%
              </option>
              <option value="70" disabled={boardB + boardC > 30}>
                70%
              </option>
              <option value="80" disabled={boardB + boardC > 20}>
                80%
              </option>
              <option value="90" disabled={boardB + boardC > 10}>
                90%
              </option>
              <option value="100" disabled={boardB + boardC > 0}>
                100%
              </option>
            </Form.Select>
          </Col>
          <Col>
            <Form.Label>Pot B</Form.Label>

            <Form.Select
              aria-label="Default select example"
              value={boardB}
              onChange={(e) => {
                setBoardB(parseInt(e.target.value));
              }}
            >
              <option value="0">0%</option>
              <option value="10" disabled={boardA + boardC > 90}>
                10%
              </option>
              <option value="20" disabled={boardA + boardC > 80}>
                20%
              </option>
              <option value="30" disabled={boardA + boardC > 70}>
                30%
              </option>
              <option value="40" disabled={boardA + boardC > 60}>
                40%
              </option>
              <option value="50" disabled={boardA + boardC > 50}>
                50%
              </option>
              <option value="60" disabled={boardA + boardC > 40}>
                60%
              </option>
              <option value="70" disabled={boardA + boardC > 30}>
                70%
              </option>
              <option value="80" disabled={boardA + boardC > 20}>
                80%
              </option>
              <option value="90" disabled={boardA + boardC > 10}>
                90%
              </option>
              <option value="100" disabled={boardA + boardC > 0}>
                100%
              </option>
            </Form.Select>
          </Col>
          <Col>
            <Form.Label>Pot C</Form.Label>

            <Form.Select
              aria-label="Default select example"
              value={boardC}
              onChange={(e) => {
                setBoardC(parseInt(e.target.value));
              }}
            >
              <option value="0">0%</option>
              <option value="10" disabled={boardA + boardB > 90}>
                10%
              </option>
              <option value="20" disabled={boardA + boardB > 80}>
                20%
              </option>
              <option value="30" disabled={boardA + boardB > 70}>
                30%
              </option>
              <option value="40" disabled={boardA + boardB > 60}>
                40%
              </option>
              <option value="50" disabled={boardA + boardB > 50}>
                50%
              </option>
              <option value="60" disabled={boardA + boardB > 40}>
                60%
              </option>
              <option value="70" disabled={boardA + boardB > 30}>
                70%
              </option>
              <option value="80" disabled={boardA + boardB > 20}>
                80%
              </option>
              <option value="90" disabled={boardA + boardB > 10}>
                90%
              </option>
              <option value="100" disabled={boardA + boardB > 0}>
                100%
              </option>
            </Form.Select>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" size="sm" onClick={handleSave}>
          Save Changes {isLoading && <Spinner animation="grow" size="sm" />}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Lucky77Settings;
