import { getLucky77EarningStateData } from "api/chart";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, ArcElement } from "chart.js";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import { numberFormatter } from "utils/number";

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Tooltip);

const Lucky77EarningChart = () => {
  const [data, setData] = useState();
  const [type, setType] = useState("daily");

  const loadData = async () => {
    try {
      const {
        data: { expense, earning },
      } = await getLucky77EarningStateData(type);
      setData({
        labels: ["Expense", "Earning"],
        datasets: [
          {
            label: "# beans",
            data: [expense, earning],
            backgroundColor: ["rgba(255, 0, 0, 0.3)", "rgba(0, 255, 0, 0.3)"],
            borderColor: ["rgba(153, 102, 255, 1)", "rgba(255, 159, 64, 1)"],
            borderWidth: 1,
          },
        ],
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadData();
  }, [type]);

  return (
    <div className="card card-success">
      <div className="card-header">
        <h4 className="card-title text-center">Lucky77 Expense vs Earning</h4>

        <div className="d-flex justify-content-center mt-2 mb-2">
          <Form.Check checked={type == "daily"} onChange={() => setType("daily")} type="radio" label="Daily" id="daily_123434561" />
          <Form.Check checked={type == "weekly"} onChange={() => setType("weekly")} type="radio" label="Weekly" id="weekly_45345345" />
          <Form.Check checked={type == "monthly"} onChange={() => setType("monthly")} type="radio" label="Monthly" id="monthly345_324123" />
        </div>

        <h5 className={`text-center ${data?.datasets[0]?.data[0] - data?.datasets[0]?.data[1] >= 0 ? "text-success" : "text-danger"} `}>
          {data?.datasets[0]?.data[0] - data?.datasets[0]?.data[1] >= 0 ? "Profit: " : "Loss: "} {numberFormatter(Math.abs(data?.datasets[0]?.data[0] - data?.datasets[0]?.data[1]))}
        </h5>
      </div>
      <div className="card-body">
        <div className="chart">{data != null && <Pie data={data} />}</div>
      </div>
    </div>
  );
};

export default Lucky77EarningChart;
