import React from "react";
import { useState } from "react";

// react-bootstrap components
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useEffect } from "react";
import { getHistory } from "api/lucky77";
import lichi from "../../assets/img/lichi.png";
import sevenSeven from "../../assets/img/77.png";
import wattermilon from "../../assets/img/wattermilon.png";

function Lucky77History({ show, onHide }) {
  const [isLoading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);

  const loadGameHistory = async () => {
    try {
      setLoading(true);
      const { data } = await getHistory();
      setHistory(data?.list);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      NotificationManager.error(
        error?.response?.data?.error?.message || error.message
      );
    }
  };

  useEffect(() => {
    if (show) {
      loadGameHistory();
    }
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && (
          <div className="text-center py-4">
            <Spinner animation="grow" />
          </div>
        )}

        <div className="d-flex flex-wrap justify-content-center">
          {history.map(({ winPot }, idx) => (
            <div className="icon p-2" key={idx}>
              <img
                src={
                  winPot === "A"
                    ? lichi
                    : winPot === "B"
                    ? sevenSeven
                    : wattermilon
                }
                style={{
                  width: 30,
                  height: 30,
                }}
              />
            </div>
          ))}
        </div>

        <div className="footer">
          <div className="item">
            <img src={lichi} className="img" />
            {Math.round(
              (100 * history.filter((i) => i.winPot === "A").length) /
                history.length
            )}
            %
          </div>
          <div className="item">
            <img src={sevenSeven} className="img" />
            {Math.round(
              (100 * history.filter((i) => i.winPot === "B").length) /
                history.length
            )}
            %
          </div>
          <div className="item">
            <img src={wattermilon} className="img" />
            {Math.round(
              (100 * history.filter((i) => i.winPot === "C").length) /
                history.length
            )}
            %
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="ms-auto"
          variant="secondary"
          size="sm"
          onClick={onHide}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Lucky77History;
