import React from "react";
import { useState } from "react";

// react-bootstrap components
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useEffect } from "react";
import { getHistory } from "api/teenPatti";

function TeenPattiHistory({ show, onHide }) {
  const [isLoading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);

  const loadGameHistory = async () => {
    try {
      setLoading(true);
      const { data } = await getHistory();
      setHistory(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      NotificationManager.error(
        error?.response?.data?.error?.message || error.message
      );
    }
  };

  useEffect(() => {
    if (show) {
      loadGameHistory();
    }
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped>
          <thead>
            <tr>
              <th>
                <span className="text-center d-block display-6">A</span>
              </th>
              <th>
                <span className="text-center d-block display-6">B</span>
              </th>
              <th>
                <span className="text-center d-block display-6">C</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {history.map(({ winPot }, i) => (
              <tr kay={i}>
                <td className="text-center">
                  {winPot === "A" ? "Win" : "Loss"}
                </td>
                <td className="text-center">
                  {winPot === "B" ? "Win" : "Loss"}
                </td>
                <td className="text-center">
                  {winPot === "C" ? "Win" : "Loss"}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {isLoading && (
          <div className="text-center py-4">
            <Spinner animation="grow" />
          </div>
        )}

        {/* 
        <div className="footer">
          <div className="item">
            <span className="display-6 me-2">A</span>
            {Math.round(
              (100 * history.filter((i) => i.winPot === "A").length) /
                history.length
            )}
            %
          </div>
          <div className="item">
            <span className="display-6 me-2">B</span>
            {Math.round(
              (100 * history.filter((i) => i.winPot === "B").length) /
                history.length
            )}
            %
          </div>
          <div className="item">
            <span className="display-6 me-2">C</span>
            {Math.round(
              (100 * history.filter((i) => i.winPot === "C").length) /
                history.length
            )}
            %
          </div>
        </div> */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="ms-auto"
          variant="secondary"
          size="sm"
          onClick={onHide}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TeenPattiHistory;
