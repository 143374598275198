import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stats: {
    totalPlayers: 0,
    totalGames: 0,
  },
};

export const dashboard = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    changeStats: (state, { payload }) => {
      state.stats = payload;
    },
  },
});

export const { changeStats } = dashboard.actions;

export default dashboard.reducer;
