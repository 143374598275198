import React, { useEffect, useState } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { getHourlyChartData } from "api/chart";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const HourlyChart = ({ name = "TEEN_PATTI" }) => {
  const [data, setData] = useState();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `last 24 hours Earning vs Expense Chart of ${name?.split("_").join(" ")}`,
      },
    },
  };

  const convertUtcHourToLocal = (utcHour) => {
    let now = new Date();
    now.setUTCHours(parseInt(utcHour));
    const localHour = now.toLocaleString("en-US", { hour: "numeric", hour12: true });
    return localHour;
  };

  const loadData = async () => {
    try {
      const { data } = await getHourlyChartData(name);

      setData({
        labels: data.map((i) => convertUtcHourToLocal(i._id)),
        datasets: [
          {
            label: "Earning",
            data: data.map((i) => i.earning),
            backgroundColor: "rgba(0, 255, 0, 0.3)",
          },
          {
            label: "Expense",
            data: data.map((i) => i.expense),
            backgroundColor: "rgba(255, 0, 0, 0.3)",
          },
        ],
      });

      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="card card-success">
      <div className="card-body">
        <div className="chart">{data != null && <Bar options={options} data={data} />}</div>
      </div>
    </div>
  );
};

export default HourlyChart;
