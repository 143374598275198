const Sort = ({ orders, name, handleOrder }) => {
  return (
    <>
      <i
        className={`fas fa-sort-numeric-down ${
          orders.find((order) => order.key === name && order.value === "ASC")
            ? "text-info"
            : "text-dark"
        }  cursor-pointer`}
        onClick={() => {
          handleOrder({
            key: name,
            value: "ASC",
          });
        }}
      ></i>
      <i
        className={`fas fa-sort-numeric-up ${
          orders.find((order) => order.key === name && order.value === "DESC")
            ? "text-info"
            : "text-dark"
        }  cursor-pointer`}
        onClick={() => {
          handleOrder({
            key: name,
            value: "DESC",
          });
        }}
      ></i>
    </>
  );
};

export default Sort;
