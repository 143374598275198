import { getSettings } from "api/dashboard";
import { saveConfiguration } from "api/dashboard";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Col, FormGroup, Input, Label } from "reactstrap";

export default function Percentage({ name }) {
  const [status, setStatus] = useState("off");
  const [isLoading, setLoading] = useState(true);

  const onSettingChange = (value) => {
    setStatus(value);

    saveConfiguration(name, value)
      .then(() => {
        NotificationManager.success("Configuration Saved.");
      })
      .catch((err) => {
        NotificationManager.error(err?.response?.data?.issue?.message || "Unknown error.");
      });
  };

  const loadSettings = async () => {
    try {
      setLoading(true);

      const { data } = await getSettings();

      setStatus(data[name]);
      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    loadSettings();
  }, []);

  if (isLoading == false) {
    return (
      <Col className="mt-2">
        <FormGroup className="w-100">
          <Label>Return on invest</Label>
          <Input type="select" name="select" id="exampleSelect" onChange={(e) => onSettingChange(e.target.value)}>
            <option value="off" selected={status == "off"}>
              Off
            </option>
            <option value="30" selected={status == "30"}>
              30%
            </option>
            <option value="40" selected={status == "40"}>
              40%
            </option>
            <option value="50" selected={status == "50"}>
              50%
            </option>
            <option value="60" selected={status == "60"}>
              60%
            </option>
            <option value="70" selected={status == "70"}>
              70%
            </option>
            <option value="80" selected={status == "80"}>
              80%
            </option>
            <option value="90" selected={status == "90"}>
              90%
            </option>
            <option value="100" selected={status == "100"}>
              100%
            </option>
          </Input>
        </FormGroup>
      </Col>
    );
  }

  return <></>;
}
