import axios from "axios";
import { logout } from "store/slice/auth";

import store from "../store";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
});

api.interceptors.request.use(
  function (config) {
    const {
      auth: { user },
    } = store.getState();

    if (user) {
      config = {
        ...config,
        headers: {
          ...config?.headers,
          Authorization: `Bearer ${user.jwt}`,
        },
      };
    }

    return config;
  },

  function (error) {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch(logout());
    }

    return Promise.reject(error);
  }
);

export default api;
