import { login as adminLogin } from "api/auth";
import { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { login } from "store/slice/auth";

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    try {
      e.preventDefault();

      setLoading(true);
      const { data } = await adminLogin(email, password);

      dispatch(login(data.admin));
    } catch (error) {
      setLoading(false);

      NotificationManager.error(
        error?.response?.data?.error?.message || error.message
      );
    }
  };

  return (
    <div
      className="bg-light d-flex justify-content-center align-items-center"
      style={{
        height: "100vh",
      }}
    >
      <Container>
        <Row className="py-5 justify-content-center">
          <Col sm={12} md={6} lg={4}>
            <Card className="shadow-sm">
              <Card.Header>
                <Card.Title as="h4">Login</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleLogin}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <div className="text-center">
                    <Button
                      variant="secondary"
                      type="submit"
                      style={{
                        minWidth: 120,
                      }}
                    >
                      {isLoading && <Spinner animation="grow" size="sm" />}{" "}
                      Login
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
